import type { EditorProductInterface } from '@odo/types/portal';
import type { Attribute } from '@odo/contexts/attributes';
import { AttributeCode } from '@odo/types/api';
import { getAllSurcharges } from '@odo/helpers/attributes';
import draftDefaults from '@odo/data/product/draft/defaults';
import { EMPTY_PRODUCT } from '@odo/contexts/product-editor';

const DESCRIPTION_BASE = `
<h5>About</h5>
<p>###</p>
<h5>Product Features</h5>
<ul>
<li>###</li>
</ul>
<h5>Product Specifications</h5>
<ul>
<li>###</li>
</ul>
`;

/**
 * Adds the defaults for all drafts and those needed for creating an empty deal.
 *
 * Defaults:
 * - platforms = all
 * - priority = 50
 * - surcharges = all (but empty)
 * - buyInStockType = none
 * - customerDeliveryTime = default (from metadata)
 * - supplierRepacks = none
 * - campaign = none
 * - taxClass = taxable
 * - adminCost = R17.50
 * - condition = new
 * - warrantyPeriod = 6 months
 * - inventory.maximumSaleQuantity = 10
 * - inventory.useConfigMinQtyInCart = true
 * - inventory.useConfigMaxQtyInCart = true
 */
const createWithDefaults = (attributes: Attribute[]) => {
  const product: EditorProductInterface = { ...EMPTY_PRODUCT };

  draftDefaults(product, attributes);

  product.moreDetails = DESCRIPTION_BASE;
  product.isShippingApplied = true;

  product.inventory = {
    maximumSaleQuantity: { string: '10', number: 10 },
    useConfigMinSaleQty: true,
    useConfigMaxSaleQty: true,
  };

  product.surcharges = getAllSurcharges({ attributes });

  const customerDeliveryTimeDefault = (
    attributes?.find(attr => attr.id === AttributeCode.customerDeliveryTime)
      ?.options || []
  ).find(option =>
    (option.metadata || []).some(
      ({ key, value }) => key === 'is_default' && value === '1'
    )
  );
  if (customerDeliveryTimeDefault) {
    product.customerDeliveryTime = {
      id: customerDeliveryTimeDefault.value,
      label: customerDeliveryTimeDefault.label,
    };
  }

  const supplierRepacksNone = (
    attributes?.find(attr => attr.id === AttributeCode.supplierRepacks)
      ?.options || []
  ).find(({ key }) => key.toLowerCase() === 'none');
  if (supplierRepacksNone) {
    product.supplierRepacks = {
      id: supplierRepacksNone.value,
      label: supplierRepacksNone.label,
    };
  }

  const taxClassTaxable = (
    attributes?.find(attr => attr.id === AttributeCode.taxClass)?.options || []
  ).find(({ key }) => key.toLowerCase() === 'taxable_goods');
  if (taxClassTaxable) {
    product.taxClass = {
      id: taxClassTaxable.value,
      label: taxClassTaxable.label,
    };
  }

  const adminCost1750 = (
    attributes?.find(attr => attr.id === AttributeCode.adminCost)?.options || []
  ).find(({ key }) => ['admincost_17_50', '17_50'].includes(key.toLowerCase()));
  if (adminCost1750) {
    product.adminCost = {
      id: adminCost1750.value,
      label: adminCost1750.label,
    };
  }

  const conditionNew = (
    attributes?.find(attr => attr.id === AttributeCode.condition)?.options || []
  ).find(({ key }) => key.toLowerCase() === 'new');
  if (conditionNew) {
    product.condition = {
      id: conditionNew.value,
      label: conditionNew.label,
    };
  }

  const warrantyPeriod6Months = (
    attributes?.find(attr => attr.id === AttributeCode.warrantyPeriod)
      ?.options || []
  ).find(({ key }) =>
    ['warrantyperiod_6_months', '6_months'].includes(key.toLowerCase())
  );
  if (warrantyPeriod6Months) {
    product.warrantyPeriod = {
      id: warrantyPeriod6Months.value,
      label: warrantyPeriod6Months.label.replace(/warrantyperiod/i, ''),
    };
  }

  return product;
};

export default createWithDefaults;
