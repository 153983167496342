import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState, type ReactNode } from 'react';
import BulkEditContext from './context';
import type { GridProduct } from '@odo/components/search/types';
import { dateObjectToIso } from '@odo/utils/date';
import type { UpdateProductInput } from '@odo/types/api-new';
import type { LoadingMessage } from '@odo/components/search/bulk-edit/types';
import {
  LoadingMessageStatus,
  type BulkEditChanges,
} from '@odo/components/search/bulk-edit/types';
import { mutationUpdateProduct } from '@odo/graphql/product-new';
import { success, error } from '@odo/utils/toast';

const changesToUpdateProductInput = (
  deal: GridProduct,
  changes: BulkEditChanges
): UpdateProductInput => {
  // Overwrite daily shops if changes contain a daily shop
  const shops = changes.dailyShop
    ? [changes.dailyShop]
    : changes.removeAllShops
    ? []
    : deal.shops;

  const updateProductInput: UpdateProductInput = {
    isSampleReceived: deal.isSampleReceived,
    isPhotographedByStudio: deal.isPhotographedByStudio,

    status: changes.enabled,
    activeFromDate: changes.activeFromDate
      ? dateObjectToIso(new Date(`${changes.activeFromDate} 00:00`), true)
      : undefined,
    activeToDate: changes.activeToDate
      ? dateObjectToIso(new Date(`${changes.activeToDate} 23:59`), true)
      : undefined,

    categories: [
      ...shops,
      ...(deal.categories || []),
      ...(deal.permanentShops || []),
    ].reduce((acc, value) => {
      // NOTE: we need to convert the ID to a number and validate it
      const id = parseInt(value.id, 10);
      if (!isNaN(id)) {
        acc.push(id);
      }
      return acc;
    }, [] as number[]),
  };

  return updateProductInput;
};

const BulkEditContextProvider = ({ children }: { children: ReactNode }) => {
  const [deals, setDeals] = useState<GridProduct[]>([]);
  const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [hasFailedUploads, setHasFailedUploads] = useState(false);

  // State for manually disabling the bulk edit button if API calls fail, or data is invalid
  const [disableBulkEdit, setDisableBulkEdit] = useState(false);

  // Callback to execute after bulk edit is complete, used to refresh data on search grid
  const [onBulkEditComplete, setOnBulkEditComplete] = useState<
    (() => void) | undefined
  >();

  const selectDeal = useCallback(
    (deal: GridProduct) => {
      if (!deals.map(d => d.id).includes(deal.id))
        setDeals(prevDeals => [...prevDeals, deal]);
    },
    [deals]
  );

  const removeDeal = (deal: GridProduct) => {
    setDeals(prevDeals => prevDeals.filter(d => d.id !== deal.id));
  };

  const clearSelection = () => setDeals([]);
  const openBulkEditDialog = () => setIsBulkEditDialogOpen(true);
  const closeBulkEditDialog = useCallback(() => {
    setIsBulkEditDialogOpen(false);
    if (!loadingInternal) {
      setSaving(false);
    }
  }, [loadingInternal]);

  const uploadChanges = useCallback(
    async (
      changes: BulkEditChanges,
      setLoadingMessages: Dispatch<
        SetStateAction<Record<string, LoadingMessage>>
      >
    ) => {
      setLoadingInternal(true);
      setSaving(true);
      setHasFailedUploads(false);
      setLoadingMessages({});
      const failedUploads: GridProduct[] = [];
      await Promise.allSettled([
        ...deals.map(deal => {
          const input = changesToUpdateProductInput(deal, changes);
          setLoadingMessages(prevMessages => ({
            ...prevMessages,
            [deal.id]: {
              productId: deal.id,
              label: deal.name,
              status: LoadingMessageStatus.loading,
            },
          }));
          return mutationUpdateProduct({
            id: deal.id,
            product: input,
          })
            .then(response => {
              if (response) {
                setLoadingMessages(prevMessages => ({
                  ...prevMessages,
                  [deal.id]: {
                    productId: deal.id,
                    label: deal.name,
                    status: LoadingMessageStatus.success,
                  },
                }));
              }
            })
            .catch(() => {
              failedUploads.push(deal);
              setHasFailedUploads(true);
              setLoadingMessages(prevMessages => ({
                ...prevMessages,
                [deal.id]: {
                  productId: deal.id,
                  label: deal.name,
                  status: LoadingMessageStatus.error,
                },
              }));
            });
        }),
      ]);

      if (failedUploads.length === 0) {
        success('Bulk changes saved successfully, fetching fresh data...', {
          duration: 5000,
        });
        closeBulkEditDialog();
        setSaving(false);
        clearSelection();
      } else {
        error(
          'Some deals failed to save, please try again. Deals that were not saved are still selected.',
          { duration: 10000 }
        );
        setDeals(failedUploads);
      }
      setLoadingInternal(false);
      onBulkEditComplete && onBulkEditComplete();
    },
    [closeBulkEditDialog, deals, onBulkEditComplete]
  );

  const value = useMemo(
    () => ({
      deals,
      saving,
      selectDeal,
      removeDeal,
      clearSelection,
      isBulkEditDialogOpen,
      openBulkEditDialog,
      closeBulkEditDialog,
      disableBulkEdit,
      setDisableBulkEdit,
      setOnBulkEditComplete,
      uploadChanges,
      hasFailedUploads,
    }),
    [
      deals,
      saving,
      selectDeal,
      isBulkEditDialogOpen,
      closeBulkEditDialog,
      disableBulkEdit,
      uploadChanges,
      hasFailedUploads,
    ]
  );

  return (
    <BulkEditContext.Provider value={value}>
      {children}
    </BulkEditContext.Provider>
  );
};

export default BulkEditContextProvider;
