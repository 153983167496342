import React, { useState, useRef } from 'react';
import propTypes from 'prop-types';
import styles from './css/ImageCard.module.css';
import {
  RPSToolbar,
  RPSImage,
  RPSModal,
  RPSInput,
  RPSCheckbox,
  RPSListContainer,
  RPSButtonIcon,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { ImageTypeEnum } from '../../../constants/ODOEnums';
import { Draggable } from 'react-beautiful-dnd';
import { useSafeEffect } from 'hooks/useSafeEffect';

const ImageCard = ({
  index,
  selected,
  onSelect,
  data = '',
  onUpdate,
  onImageTypeSelect,
  onImageTypeDeselect,
  image,
  row = false,
  imageList,
  onSwapPositions,
  renderCounter,
}) => {
  const [isSelected, setIsSelected] = useState(
    selected !== undefined ? selected : false
  );

  const currentDeal = useCurrentDealSource();
  const checkboxRef1 = useRef();
  const checkboxRef2 = useRef();
  const checkboxRef3 = useRef();

  const [selectedImage, setSelectedImage] = useState('');

  const removeSelected = () => {
    image.delete = true;
    if (onUpdate) {
      onUpdate(image);
    }
    setModalOpened(false);
  };

  const handleDescriptionInput = ev => {
    image.label = ev.detail.value;
    onUpdate(image);
  };

  const [modalOpened, setModalOpened] = useState(false);

  const showDeleteConfirm = () => {
    setModalOpened(true);
  };

  const getBase64FromURL = async url => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const handleToolbarClick = async ev => {
    switch (ev.detail.buttonId) {
      case 'delete': {
        showDeleteConfirm();
        break;
      }
      case 'download': {
        const tempLink = document.createElement('a');
        const dataUrl = await getBase64FromURL(data);
        tempLink.setAttribute('href', dataUrl);
        const splitUrl = data.split('/');
        tempLink.setAttribute('download', splitUrl[splitUrl.length - 1]);
        tempLink.click();
        break;
      }
      case 'openInNewTab': {
        const tempLink = document.createElement('a');
        tempLink.setAttribute('href', data);
        tempLink.setAttribute('target', '_blank');
        tempLink.click();
        break;
      }
      case 'toggle': {
        if (image.excludeImageTypes === 0) {
          image.excludeImageTypes = 1;
          image.enabled = false;
        } else {
          image.excludeImageTypes = 0;
          image.enabled = true;
        }
        if (onUpdate) {
          onUpdate(image);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleSelect = () => {
    if (onSelect) {
      onSelect(index);
    } else {
      setIsSelected(!isSelected);
    }
  };

  const handleImageTypeSelect = imageType => ev => {
    if (ev.detail.checked) {
      onImageTypeSelect(imageType);
    } else {
      onImageTypeDeselect(imageType);
    }
  };

  const toolbarButtons = [
    {
      id: 'toggle',
      svg: image.enabled ? iconNames.passwordShow : iconNames.passwordHide,
      title: 'Toggle Image',
    },
    { id: 'delete', svg: iconNames.del, title: 'Delete Image' },
    { id: 'download', svg: iconNames.media, title: 'Download Image' },
    { id: 'openInNewTab', svg: iconNames.link, title: 'Open in New Tab' },
  ];

  useSafeEffect(
    isMounted => {
      if (isMounted) {
        // FIXME: TEMPORARY PATCH: Needs to be addressed in the Web Components.
        if (checkboxRef1.current && checkboxRef1.current.inputCtrl?.value) {
          checkboxRef1.current.inputCtrl.value.checked =
            checkboxRef1.current.checked;
          checkboxRef1.current.requestUpdate();
        }
        if (checkboxRef2.current && checkboxRef2.current.inputCtrl?.value) {
          checkboxRef2.current.inputCtrl.value.checked =
            checkboxRef2.current.checked;
          checkboxRef2.current.requestUpdate();
        }
        if (checkboxRef3.current && checkboxRef3.current.inputCtrl?.value) {
          checkboxRef3.current.inputCtrl.value.checked =
            checkboxRef3.current.checked;
          checkboxRef3.current.requestUpdate();
        }
      }
    },
    [renderCounter]
  );

  return (
    <Draggable
      key={`draggable-image-${image.id}`}
      index={index}
      draggableId={`draggable-image-${image.id}`}
    >
      {provided => (
        <div
          style={{
            position: 'relative',
            display: image.isSizeGuide ? 'none' : 'block',
          }}
        >
          {!!image?.delete && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: '5px solid #E50E62',
                zIndex: '15',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '2px',
                cursor: 'not-allowed',
                background: 'rgba(255, 255, 255, 0.6)',
                overflow: 'hidden',
              }}
              title="Save Changes to Delete"
            >
              <div
                style={{
                  background: '#E50E62',
                  width: '100%',
                  ...(row
                    ? { height: '5px', transform: 'skewY(-7deg)' }
                    : { height: '10px', transform: 'skewY(-62deg)' }),
                }}
              />
            </div>
          )}

          <div
            ref={provided.innerRef}
            className={styles.imageCard}
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style, background: 'white' }}
          >
            {row ? (
              <div className={styles.row}>
                <rps-svg svg={iconNames.move} {...provided.dragHandleProps} />

                <rps-svg
                  style={{ cursor: 'pointer' }}
                  onClick={handleSelect}
                  svg={selected ? iconNames.checkedBox : iconNames.checkBox}
                />
                <div
                  className={styles.imageContainer}
                  style={{
                    position: 'relative',
                    margin: 'auto auto',
                    userDrag: 'none',
                    userSelect: 'none',
                    marginBottom: '1rem',
                    width: '12rem',
                    height: '12rem',
                    opacity: image.enabled ? '1.0' : '0.25',
                    pointerEvents: image.enabled ? 'unset' : 'none',
                    cursor: image.enabled ? 'unset' : 'not-allowed',
                  }}
                  onClick={() => setSelectedImage(data)}
                >
                  <RPSImage
                    className="medium"
                    src={`${data}?h=160&w=160`}
                    css="picture img { object-fit: contain; background: white;  }"
                  />
                </div>
                <span
                  style={{ display: 'flex', alignItems: 'end', gap: '0rem' }}
                >
                  <RPSInput
                    className="small"
                    label="Description:"
                    value={image.label}
                    defaultValue={image.label}
                    cbInput={handleDescriptionInput}
                  />
                </span>
                <RPSListContainer className="vertical">
                  <RPSCheckbox
                    innerRef={checkboxRef1}
                    className="small"
                    label="Email"
                    checked={image.imageTypes.includes(ImageTypeEnum.IMAGE)}
                    cbInput={handleImageTypeSelect(ImageTypeEnum.IMAGE)}
                  />
                  <RPSCheckbox
                    innerRef={checkboxRef2}
                    className="small"
                    label="Website"
                    checked={image.imageTypes.includes(ImageTypeEnum.THUMBNAIL)}
                    cbInput={handleImageTypeSelect(ImageTypeEnum.THUMBNAIL)}
                  />
                  <RPSCheckbox
                    innerRef={checkboxRef3}
                    className="small"
                    label="Thumbnail"
                    checked={image.imageTypes.includes(
                      ImageTypeEnum.SMALL_IMAGE
                    )}
                    cbInput={handleImageTypeSelect(ImageTypeEnum.SMALL_IMAGE)}
                  />
                </RPSListContainer>
                <RPSToolbar
                  buttons={toolbarButtons}
                  cbClick={handleToolbarClick}
                />
              </div>
            ) : (
              <>
                <span className={styles.header}>
                  <rps-svg
                    style={{ cursor: 'pointer' }}
                    onClick={handleSelect}
                    svg={selected ? iconNames.checkedBox : iconNames.checkBox}
                  />
                  <RPSToolbar
                    buttons={toolbarButtons}
                    cbClick={handleToolbarClick}
                  />
                </span>
                <RPSListContainer
                  className="vertical"
                  css="#list-container { gap: 1rem !important; }"
                >
                  <div
                    className={styles.imageContainer}
                    style={{
                      position: 'relative',
                      margin: 'auto auto',
                      userDrag: 'none',
                      userSelect: 'none',
                      marginBottom: '1rem',
                      border: '1px solid #aaa',
                      width: '18rem',
                      height: '18rem',
                      cursor: image.enabled ? 'pointer' : 'not-allowed',
                      opacity: image.enabled ? '1.0' : '0.25',
                      pointerEvents: image.enabled ? 'unset' : 'none',
                    }}
                    onClick={() => setSelectedImage(data)}
                  >
                    <RPSImage
                      className="large"
                      src={`${data}?h=160&w=160`}
                      css="picture img { object-fit: contain; background: white; }"
                    />
                  </div>

                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <RPSButtonIcon
                      title="Move left"
                      disabled={index === 0}
                      className="small secondary outline"
                      svg={iconNames.angleLeft}
                      cbClick={() => {
                        onSwapPositions(index, index - 1);
                      }}
                    />
                    <rps-svg
                      svg={iconNames.move}
                      {...provided.dragHandleProps}
                    />

                    <RPSButtonIcon
                      title="Move right"
                      disabled={index === imageList.length - 1}
                      className="small secondary outline"
                      svg={iconNames.angleRight}
                      cbClick={() => {
                        onSwapPositions(index, index + 1);
                      }}
                    />
                  </span>

                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <RPSInput
                      className="small"
                      label="Description:"
                      value={image.label}
                      defaultValue={image.label}
                      cbInput={handleDescriptionInput}
                    />
                  </span>
                  <RPSListContainer
                    className="vertical"
                    css="#list-container { gap: 0.5rem !important; padding-left: 0.4rem; }"
                  >
                    <RPSCheckbox
                      innerRef={checkboxRef1}
                      className="small"
                      label="Email"
                      checked={image.imageTypes.includes(ImageTypeEnum.IMAGE)}
                      cbInput={handleImageTypeSelect(ImageTypeEnum.IMAGE)}
                    />
                    <RPSCheckbox
                      innerRef={checkboxRef2}
                      className="small"
                      label="Website"
                      checked={image.imageTypes.includes(
                        ImageTypeEnum.THUMBNAIL
                      )}
                      cbInput={handleImageTypeSelect(ImageTypeEnum.THUMBNAIL)}
                    />
                    <RPSCheckbox
                      innerRef={checkboxRef3}
                      className="small"
                      label="Thumbnail"
                      checked={image.imageTypes.includes(
                        ImageTypeEnum.SMALL_IMAGE
                      )}
                      cbInput={handleImageTypeSelect(ImageTypeEnum.SMALL_IMAGE)}
                    />
                  </RPSListContainer>
                </RPSListContainer>
              </>
            )}

            <RPSModal
              popup
              opened={modalOpened}
              cbConfirm={removeSelected}
              cbCancel={() => setModalOpened(false)}
            >
              Are you sure you want to delete this image?
            </RPSModal>

            {selectedImage && (
              <RPSModal
                popup
                opened={selectedImage || undefined}
                cbConfirm={() => {}}
                cbCancel={() => setSelectedImage('')}
              >
                <div
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '500px',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    alt="Modal preview"
                    src={selectedImage}
                    style={{
                      display: 'block',
                      width: '100%',
                      maxHeight: '500px',
                      objectFit: 'scale-down',
                      overflow: 'hidden',
                    }}
                  />
                </div>

                <div slot="footer" />
              </RPSModal>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

ImageCard.propTypes = {
  index: propTypes.number,
  title: propTypes.string,
  data: propTypes.string,
  selected: propTypes.bool,
  onSelect: propTypes.func,
  onUpdate: propTypes.func,
  image: propTypes.object,
  row: propTypes.bool,
  onImageTypeSelect: propTypes.func,
  onImageTypeDeselect: propTypes.func,
  imageList: propTypes.array,
  onSwapPositions: propTypes.func,
  renderCounter: propTypes.number,
};

export default ImageCard;
