import { Fieldset } from '@odo/components/elements/form-fields';
import { Box } from '@odo/components/elements/layout';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import type { ReactNode } from 'react';
import { useCallback, useRef, useEffect } from 'react';
import styled from '@odo/lib/styled';
import 'quill/dist/quill.snow.css';
import Quill from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Link from 'quill/formats/link';
import Header from 'quill/formats/header';
import List from 'quill/formats/list';
import Indent from 'quill/formats/indent';
import { Label } from '@odo/components/elements/form-fields/shared-styles';
import { breakpointsEm } from '@odo/contexts/theme/provider';

/**
 * NOTE: each module we choose to use below, must first be registered here.
 */
Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/underline': Underline,
  'formats/link': Link,
  'formats/header': Header,
  'formats/list': List,
  'formats/indent': Indent,
});

const TextEditorFieldset = styled(Fieldset)`
  padding: 6px 12px 8px;
`;

/**
 * NOTE: the RP styles from general.css are interfering with the bold & italic styles.
 * We're circumventing this by using `all: unset` before adding back the required font styles.
 * Ideally we don't want to have to do this, so let's look into improving once that stylesheet is removed.
 */
const TextEditorWrapper = styled(Box)`
  --border-radius: 4px;

  &.borderless {
    .ql-toolbar {
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
    }

    .ql-container {
      background: none;
      border-bottom-width: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
  }

  .ql-toolbar {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .ql-container {
    overflow: scroll;
    max-height: 65vh;
    max-height: 70dvh;
    @media screen and (min-width: ${breakpointsEm[2]}em) {
      max-height: 70vh;
      max-height: 75dvh;
    }

    background: var(--odo-color-background);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

    .ql-editor p,
    .ql-editor h1,
    .ql-editor h2,
    .ql-editor h3,
    .ql-editor h4,
    .ql-editor h5,
    .ql-editor h6,
    .ql-editor ol,
    .ql-editor ul,
    .ql-editor li {
      margin: 0.7em 0;
    }

    .ql-editor em,
    .ql-editor i {
      all: unset;
      font-style: italic;
    }

    .ql-editor strong,
    .ql-editor b {
      all: unset;
      font-weight: 800;
    }

    .ql-editor ol,
    .ql-editor ul {
      padding-left: 0;
    }

    .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
    }
    .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
      padding-left: 4.5em;
    }
    .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
      padding-left: 6em;
    }
    .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
      padding-left: 7.5em;
    }
    .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
      padding-left: 9em;
    }
    .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
      padding-left: 10.5em;
    }
    .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
      padding-left: 12em;
    }
    .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
      padding-left: 13.5em;
    }

    .ql-editor h1 {
      font-size: 1.4em;
    }
    .ql-editor h2 {
      font-size: 1.3em;
    }
    .ql-editor h3 {
      font-size: 1.25em;
    }
    .ql-editor h4 {
      font-size: 1.2em;
    }
    .ql-editor h5 {
      font-size: 1.1em;
    }
    .ql-editor h6 {
      font-size: 1em;
    }
  }
`;

interface TextEditorProps {
  label?: string;
  value: string;
  onChange: (html: string) => void;
  variant?: 'fieldset' | 'box';
}

const VariantWrapper = ({
  children,
  label,
  variant,
}: {
  children: ReactNode;
  label: TextEditorProps['label'];
  variant: TextEditorProps['variant'];
}) => (
  <>
    {variant === 'fieldset' ? (
      <TextEditorFieldset>
        {!!label && <legend>{label}</legend>}
        {children}
      </TextEditorFieldset>
    ) : (
      <Box>
        {!!label && <Label>{label}</Label>}
        {children}
      </Box>
    )}
  </>
);

const TextEditor = ({
  label,
  value,
  onChange,
  variant = 'box',
}: TextEditorProps) => {
  const quillContainerRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const quillCleanup = useRef<() => void | undefined>();

  const initializeQuill = useCallback(
    (details: string) => {
      const container = quillContainerRef.current;
      if (!container) return;

      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      );
      editorContainer.innerHTML = details || '';

      const toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // text sizes
        ['bold', 'italic', 'underline', 'link'], // styling
        [{ list: 'ordered' }, { list: 'bullet' }], // lists
        [{ indent: '+1' }, { indent: '-1' }], // indent/outdent
        ['clean'], // clear formatting
      ];

      const quill = new Quill(editorContainer, {
        theme: 'snow', // TODO: build a dark theme (or override styles in our Editor wrapper component)
        modules: {
          toolbar: toolbarOptions,
        },
        /**
         * NOTE: each of these must be registered in the Quill.register() call above before they can be used here.
         */
        formats: [
          'bold',
          'italic',
          'underline',
          'link',
          'header',
          'list',
          'indent',
        ],
      });

      quillRef.current = quill;

      quill.on(Quill.events.TEXT_CHANGE, () => {
        const html = quill.getSemanticHTML();
        if (html) {
          onChange(html);
        }
      });

      return () => {
        quillRef.current = null;
        container.innerHTML = '';
      };
    },
    [onChange]
  );

  /**
   * Initialize the quill editor.
   *
   * NOTE: we do our cleanup before re-initializing instead of on dismount of the effect.
   * This is because we need the latest value of `getSemanticHTML` and would lose it on cleanup.
   * It doesn't cause any issues doing it later, because the only real reason to cleanup is to avoid duplicating content
   * if you re-initialize the editor while it still has content in it.
   */
  useEffect(() => {
    if (!quillRef.current || value !== quillRef.current.getSemanticHTML()) {
      quillCleanup.current && quillCleanup.current();
      quillCleanup.current = initializeQuill(value || '');
    }
  }, [initializeQuill, value, variant]);

  return (
    <VariantWrapper label={label} variant={variant}>
      <TextEditorWrapper
        className={variant === 'fieldset' ? 'borderless' : undefined}
      >
        <div ref={quillContainerRef} />
      </TextEditorWrapper>
    </VariantWrapper>
  );
};

const Wrapper = (props: TextEditorProps) => (
  <ErrorBoundary errorDisplay="Failed to load editor. Please refresh and try again.">
    <TextEditor {...props} />
  </ErrorBoundary>
);

export default Wrapper;
