import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { useProductEditor } from '@odo/contexts/product-editor';
import usePreventCloseTab from '@odo/hooks/use-prevent-close-tab';
import { useDealId } from '@odo/screens/deal/editor/hooks';

const PreventLosingChanges = () => {
  const { isDraft } = useDealId();
  const { hasChanges } = useProductEditor();
  const { hasUnsavedActions } = useCustomOptionsEditorContext();

  // we don't need to prevent leaving if the deal is a draft as that data is cached.
  usePreventCloseTab({
    message: 'You will lose any unsaved changes to this deal.',
    show: !(isDraft || (!hasChanges && !hasUnsavedActions)),
  });

  return null;
};

export default PreventLosingChanges;
