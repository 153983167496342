import type { CategoryOrBreadcrumb } from '@odo/types/portal';

export enum LoadingMessageStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export interface LoadingMessage {
  productId: string;
  label: string;
  status: LoadingMessageStatus;
}

export interface BulkEditChanges {
  enabled?: boolean;
  dailyShop?: CategoryOrBreadcrumb;
  activeFromDate?: string;
  activeToDate?: string;
  removeAllShops?: boolean;
}
