import type {
  BulkEditChanges,
  LoadingMessage,
} from '@odo/components/search/bulk-edit/types';
import type { GridProduct } from '@odo/components/search/types';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

interface BulkEditContextInterface {
  deals: GridProduct[];
  saving: boolean;
  selectDeal: (deal: GridProduct) => void;
  removeDeal: (deal: GridProduct) => void;
  clearSelection: () => void;
  isBulkEditDialogOpen: boolean;
  openBulkEditDialog: () => void;
  closeBulkEditDialog: () => void;
  disableBulkEdit: boolean;
  setDisableBulkEdit: (status: boolean) => void;
  setOnBulkEditComplete: Dispatch<SetStateAction<(() => void) | undefined>>;
  uploadChanges: (
    changes: BulkEditChanges,
    setLoadingMessages: Dispatch<SetStateAction<Record<string, LoadingMessage>>>
  ) => void;
  hasFailedUploads: boolean;
}

const BulkEditContext = createContext<BulkEditContextInterface | undefined>({
  deals: [],
  saving: false,
  selectDeal: () => null,
  removeDeal: () => null,
  clearSelection: () => null,
  isBulkEditDialogOpen: false,
  openBulkEditDialog: () => null,
  closeBulkEditDialog: () => null,
  disableBulkEdit: false,
  setDisableBulkEdit: () => null,
  setOnBulkEditComplete: (
    _callback: SetStateAction<(() => void) | undefined>
  ) => void 0,
  uploadChanges: () => null,
  hasFailedUploads: false,
});

export default BulkEditContext;
