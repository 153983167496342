import { useEffect } from 'react';

const usePreventCloseTab = (config: { message: string; show: boolean }) => {
  useEffect(() => {
    if (!config.show) return;

    const confirmLeaving = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return config.message;
    };

    window.addEventListener('beforeunload', confirmLeaving);
    return () => window.removeEventListener('beforeunload', confirmLeaving);
  }, [config]);
};

export default usePreventCloseTab;
